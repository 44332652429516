import React, { memo, useRef } from "react";

import Settings from "./settings.png";
import { useDetectOutsideClick } from "../../../../common/hooks/use-detect-outside-click";
import "./settings-menu.scss";

const SettingsMenu = () => {
  const dropdownRef = useRef(null);
  const imgRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, imgRef, false);

  const onClick = () => setIsActive(!isActive);

  const onSettingsClick = evt => {
    evt.preventDefault();
  };

  return (
    <div className="dropdown-item d-flex align-items-center">
      <img ref={imgRef} onClick={onClick} src={Settings} width="25" alt="Settings" />
      <nav
        ref={dropdownRef}
        className={`menu ${isActive ? "active" : "inactive"}`}
      >
        <ul>
          <li>
            <span onClick={onSettingsClick}>Some option here</span>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default memo(SettingsMenu);
