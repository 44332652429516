import React from 'react'

import "./login.scss";
import GoogleImg from "./google.svg";
import {useAuthDataContext} from "../../common/providers/user-provider";

const Login = () => {
  const { signIn } = useAuthDataContext();

  return (
    <section className="login">
      <button onClick={signIn} className="login-hook-btn">
        <img src={GoogleImg} alt="google login" className="icon" />
        <span className="buttonText">Sign in with Google</span>
      </button>
    </section>
  )
};

export default Login;
