import React, { memo, useRef } from "react";

import User from "./user.png";
import { useDetectOutsideClick } from "../../../../common/hooks/use-detect-outside-click";
import { useAuthDataContext } from "../../../../common/providers/user-provider";
import "./user-menu.scss";

const UserMenu = () => {
  const { user, signOut } = useAuthDataContext();
  const dropdownRef = useRef(null);
  const imgRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, imgRef, false);

  const onClick = () => setIsActive(!isActive);

  const onSignOutClick = evt => {
    evt.preventDefault();
    localStorage.removeItem('data');
    window.postMessage({
      direction: "from-page-script",
      message: "sign-out",
      data: null
    }, "*");
    signOut();
  };

  return (
    <div className="dropdown-item d-flex align-items-center">
      <img ref={imgRef} onClick={onClick} src={User} width="25" alt={user.name} />
      <nav
        ref={dropdownRef}
        className={`menu ${isActive ? "active" : "inactive"}`}
      >
        <ul>
          <li>
            <span onClick={onSignOutClick}>Sign out</span>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default memo(UserMenu);
