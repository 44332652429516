import { useEffect, useState } from "react";
import debounce from "lodash.debounce";

export function useWindowSize(delay = 500) {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    const debouncedHandleResize = debounce(handleResize, delay);

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return windowSize;
}
