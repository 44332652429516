import React, {
  createContext, useState, useMemo, useContext
} from "react";

import Spinner from "../components/spinner";

export const LoadingDataContext = createContext(null);

const LoadingDataProvider = props => {
  const [isLoadingVisible, setIsLoadingVisible] = useState(false);

  const showLoading = () => setIsLoadingVisible(true);

  const hideLoading = () => setIsLoadingVisible(false);

  const loadingDataValue = useMemo(() =>
      ({ loading: isLoadingVisible, showLoading, hideLoading }),
    [isLoadingVisible]
  );

  return <LoadingDataContext.Provider
    value={ loadingDataValue } { ...props }
  >
    { props.children }
    { isLoadingVisible && <Spinner /> }
  </LoadingDataContext.Provider>
};

export const useLoadingDataContext = () => useContext(LoadingDataContext);

export default LoadingDataProvider;
