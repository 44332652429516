import React from "react";

import "./spinner.scss";

const Spinner = () => {
  return (
    <div className="d-flex align-items-center spinner">
      <img
        alt="Spinner"
        width="24"
        height="24"
        src="https://i.pinimg.com/originals/3e/f0/e6/3ef0e69f3c889c1307330c36a501eb12.gif"
      />
    </div>
  )
};

export default Spinner;
