import React, { memo } from 'react'
import { Route, Switch } from "react-router-dom";

import DashboardHeader from "./components/dashboard-header";
import DashboardSideMenu from "./components/dashboard-side-menu";
import ExtensionSettings from "../extension-settings";
// import Notifications from "../notifications";
// import NetworkInsights from "../network-insights";

const Dashboard = () => {
  return (
    <section className="d-flex flex-column">
      <DashboardHeader />
      <div className="d-flex flex-row">
        <DashboardSideMenu />
        <>
          <Switch>
            <Route path="/" component={ ExtensionSettings } />
          </Switch>
          {/*<Switch>*/}
          {/*  <Route path="/" exact={true} component={ NetworkInsights } />*/}
          {/*  <Route path="/settings" component={ ExtensionSettings } />*/}
          {/*  <Route path="/notifications" component={ Notifications } />*/}
          {/*</Switch>*/}
        </>
      </div>
    </section>
  )
};

export default memo(Dashboard);
