import { useState, useEffect, useCallback } from "react";

/**
 * Hook for handling closing when clicking outside of an element
 * @param {React.node} el
 * @param {React.node} imgRef
 * @param {boolean} initialState
 */

export const useDetectOutsideClick = (el, imgRef, initialState) => {
  const [isActive, setIsActive] = useState(initialState);

  const onClick = useCallback(e => {
    if (!imgRef.current.contains(e.target) && !el.current.contains(e.target)) {
      setIsActive(false);
      return;
    }

    if (el.current && !el.current.contains(e.target)) {
      setIsActive(!isActive);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsActive]);

  useEffect(() => {
    if (isActive) {
      window.addEventListener("click", onClick);
    }

    return () => {
      window.removeEventListener("click", onClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, el, imgRef]);

  return [isActive, setIsActive];
};
