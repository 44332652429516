const getToken = () => {
  const getStorageData = JSON.parse(localStorage.getItem('data'));
  return getStorageData ? getStorageData.token : '';
};

export const Get = async (url) => {
  const response = await fetch(`https://chrome_ex_dev.hubbleiq.com/api/${url}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      'Authorization': getToken()
    }
  });

  return await response.json();
};

export const Put = async (url, data) => {
  const response = await fetch(`https://chrome_ex_dev.hubbleiq.com/api/${url}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      'Authorization': getToken()
    },
    body: JSON.stringify(data)
  });

  return await response.json();
};

export const Post = async (url, data) => {
  const response = await fetch(`https://chrome_ex_dev.hubbleiq.com/api/${url}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      'Authorization': getToken()
    },
    body: JSON.stringify(data)
  });

  return await response.json();
};
