import React from 'react'
import { BrowserRouter } from "react-router-dom";
import { ToastProvider } from 'react-toast-notifications'

import LoadingDataProvider from "./common/providers/loading-provider";
import Router from "./router";

const App = () => (
  <BrowserRouter>
    <LoadingDataProvider>
      <ToastProvider>
        <Router />
      </ToastProvider>
    </LoadingDataProvider>
  </BrowserRouter>
);

export default App;
