import React from "react";
import { Switch } from "react-router-dom";

import AuthDataProvider from "./common/providers/user-provider";
import PrivateRoute from "./common/components/private-route";
import Dashboard from "./views/dashboard";

const Router = () => {
  return (
    <AuthDataProvider>
      <Switch>
        <PrivateRoute path="/" component={ Dashboard } />
      </Switch>
    </AuthDataProvider>
  );
};

export default Router;
