import React, { memo } from "react";
import { Route } from "react-router-dom";

import { useAuthDataContext } from "../../providers/user-provider";
import Login from "../../../views/login";

const PrivateRoute = ({ component, ...rest }) => {
  const { user } = useAuthDataContext();
  return <Route { ...rest } component={ user ? component : Login } />;
};

export default memo(PrivateRoute)
