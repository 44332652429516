import React, { memo } from "react";

import "./dashboard-header.scss";
import Logo from "./black-logo.png";
import UserMenu from "../user-menu";
import SettingsMenu from "../settings-menu";

const DashboardHeader = () => {
  return (
    <div className="dashboard-header d-flex justify-content-between align-items-center">
      <img className="logo" width="130" src={Logo} alt="HubbleIQ" />
      <div className="d-flex align-items-center">
        <div className="mr-15">
          <SettingsMenu />
        </div>
        <UserMenu />
      </div>
    </div>
  );
};

export default memo(DashboardHeader);
