import React, {
  createContext, useState, useMemo, useContext, useEffect
} from "react";
import { useGoogleLogin, useGoogleLogout } from "react-google-login";
import { differenceInMinutes, isBefore } from 'date-fns'

import { CLIENT_ID } from "../../constants";
import { useLoadingDataContext } from "./loading-provider";
import { checkToken } from "../services/check-token";

export const AuthDataContext = createContext(null);
const dataFromStorage = localStorage.getItem('data');
let getUser = JSON.parse(dataFromStorage ? dataFromStorage : null);

const AuthDataProvider = props => {
  const [user, setUser] = useState(null);
  const { showLoading, hideLoading } = useLoadingDataContext();

  useEffect(() => {
    showLoading();
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!getUser) {
      setUser(null);
      hideLoading();
      return;
    }

    if (!user && getUser && !getUser.webAuth) {
      checkToken(getUser.token).then(res => {
        if (res.error) {
          const timeout = setTimeout(() => {
            window.postMessage({
              direction: "from-page-script",
              message: "check-token",
              data: {
                token: getUser.token,
              }
            }, "*");

            const secondTimeout = setTimeout(() => {
              const updatedUser = JSON.parse(localStorage.getItem('data'));
              setUser(updatedUser);
              hideLoading();
              clearTimeout(secondTimeout);
              clearTimeout(timeout);
            }, 1000);
          }, 1000);
        } else {
          setUser(getUser);
          hideLoading();
        }
      });
    } else {
      setUser(getUser);
      hideLoading();
    }

    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onSuccess = (res) => {
    console.log('res', res);
    const data = {
      ...res.profileObj,
      token: res.accessToken,
      expires_at: res.tokenObj.expires_at,
      webAuth: true,
    };

    localStorage.setItem('data', JSON.stringify(data));

    if (user) {
      window.postMessage({
        direction: "from-page-script",
        message: "token-refresh",
        data: {
          newToken: res.accessToken,
        },
      });
    } else {
      window.postMessage({
        direction: "from-page-script",
        message: "sign-in",
        data,
      }, "*");
      window.location.reload();
    }
  };

  const onFailure = (res) => {
    getUser = null;
    setUser(null);
    hideLoading();
  };

  const onLogoutSuccess = () => {
    getUser = null;
    setUser(null);
    window.postMessage({
      direction: "from-page-script",
      message: "sign-out",
      data: null
    }, "*");
  };

  const checkTokenIsValid = user => {
    if (!user || !user.webAuth) {
      return false
    }
    const dateNow = new Date();
    const expireDate = new Date(getUser.expires_at);
    return isBefore(dateNow, expireDate) && differenceInMinutes(dateNow, expireDate) < -5;
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId: CLIENT_ID,
    accessType: 'offline',
    isSignedIn: !checkTokenIsValid(getUser),
  });

  const { signOut } = useGoogleLogout({
    clientId: CLIENT_ID,
    onLogoutSuccess,
    onFailure,
  });

  const authDataValue = useMemo(() =>
    ({
      user,
      signIn,
      signOut,
    }),
    [signIn, signOut, user]
  );

  return <AuthDataContext.Provider value={ authDataValue } { ...props } />;
};

export const useAuthDataContext = () => useContext(AuthDataContext);

export default AuthDataProvider;
