import React, { memo, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import "./dashboard-side-menu.scss";
import { useWindowSize } from "../../../../common/hooks/window-resize";

const DashboardSideMenu = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const size = useWindowSize();

  useEffect(() => {
    setIsExpanded(size.width > 768);

    return () => null;
  }, [size]);

  const onExpandBlockClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`dashboard-side-menu d-flex ${isExpanded ? 'expanded' : 'closed'}`}>
      <div className="menu-categories d-flex flex-column">
        <div className="category d-flex flex-column">
          {
            isExpanded && (<span className="category-name">MENU</span>)
          }
          <div className="category-items">
            {/*<div className="category-item">*/}
            {/*  <NavLink*/}
            {/*    to="/"*/}
            {/*    className={`d-flex ${!isExpanded ? 'justify-content-center' : ''}`}*/}
            {/*    activeClassName="active"*/}
            {/*    exact*/}
            {/*  >*/}
            {/*    <svg width="17" height="17" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*      <path d="M11.3129 22.7376C17.5121 22.7376 22.64 17.5876 22.64 11.376C22.64 5.15004 17.5263 0 11.3271 0C10.7558 0 10.4987 0.32996 10.4987 0.87507V5.25046C10.4987 5.68084 10.7844 6.03948 11.2271 6.03948C11.67 6.03948 11.9556 5.68084 11.9556 5.25046V1.70713C17.0264 2.03707 20.9545 6.22595 20.9545 11.376C20.9545 16.7412 16.6693 21.0592 11.3129 21.0592C5.97068 21.0592 1.65693 16.7412 1.67121 11.376C1.68551 8.92291 2.5711 6.71371 4.05662 5.00658C4.35659 4.63361 4.38517 4.16021 4.05662 3.81591C3.72811 3.47161 3.18531 3.50032 2.82821 3.93067C1.07128 5.93906 0 8.5356 0 11.376C0 17.5876 5.12791 22.7376 11.3129 22.7376ZM13.0269 12.9827C13.8697 12.0933 13.684 10.9026 12.6841 10.1853L6.81344 6.03948C6.29919 5.66648 5.75642 6.21162 6.1278 6.72805L10.2558 12.6241C10.97 13.6282 12.1556 13.8291 13.0269 12.9827Z" fill="#B8B5FF"/>*/}
            {/*    </svg>*/}
            {/*    {*/}
            {/*      isExpanded && (<span>Network Insights</span>)*/}
            {/*    }*/}
            {/*  </NavLink>*/}
            {/*</div>*/}
            {/*<div className="category-item">*/}
            {/*  <NavLink*/}
            {/*    to="/notifications"*/}
            {/*    className={`d-flex ${!isExpanded ? 'justify-content-center' : ''}`}*/}
            {/*    activeClassName="active"*/}
            {/*  >*/}
            {/*    <svg width="17" height="17" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*      <path d="M11.3129 22.7376C17.5121 22.7376 22.64 17.5876 22.64 11.376C22.64 5.15004 17.5263 0 11.3271 0C10.7558 0 10.4987 0.32996 10.4987 0.87507V5.25046C10.4987 5.68084 10.7844 6.03948 11.2271 6.03948C11.67 6.03948 11.9556 5.68084 11.9556 5.25046V1.70713C17.0264 2.03707 20.9545 6.22595 20.9545 11.376C20.9545 16.7412 16.6693 21.0592 11.3129 21.0592C5.97068 21.0592 1.65693 16.7412 1.67121 11.376C1.68551 8.92291 2.5711 6.71371 4.05662 5.00658C4.35659 4.63361 4.38517 4.16021 4.05662 3.81591C3.72811 3.47161 3.18531 3.50032 2.82821 3.93067C1.07128 5.93906 0 8.5356 0 11.376C0 17.5876 5.12791 22.7376 11.3129 22.7376ZM13.0269 12.9827C13.8697 12.0933 13.684 10.9026 12.6841 10.1853L6.81344 6.03948C6.29919 5.66648 5.75642 6.21162 6.1278 6.72805L10.2558 12.6241C10.97 13.6282 12.1556 13.8291 13.0269 12.9827Z" fill="#B8B5FF"/>*/}
            {/*    </svg>*/}
            {/*    {*/}
            {/*      isExpanded && (<span>Notifications</span>)*/}
            {/*    }*/}
            {/*  </NavLink>*/}
            {/*</div>*/}
            <div className="category-item">
              <NavLink
                to="/settings"
                className={`d-flex ${!isExpanded ? 'justify-content-center' : ''}`}
                activeClassName="active"
              >
                <svg width="17" height="17" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.3129 22.7376C17.5121 22.7376 22.64 17.5876 22.64 11.376C22.64 5.15004 17.5263 0 11.3271 0C10.7558 0 10.4987 0.32996 10.4987 0.87507V5.25046C10.4987 5.68084 10.7844 6.03948 11.2271 6.03948C11.67 6.03948 11.9556 5.68084 11.9556 5.25046V1.70713C17.0264 2.03707 20.9545 6.22595 20.9545 11.376C20.9545 16.7412 16.6693 21.0592 11.3129 21.0592C5.97068 21.0592 1.65693 16.7412 1.67121 11.376C1.68551 8.92291 2.5711 6.71371 4.05662 5.00658C4.35659 4.63361 4.38517 4.16021 4.05662 3.81591C3.72811 3.47161 3.18531 3.50032 2.82821 3.93067C1.07128 5.93906 0 8.5356 0 11.376C0 17.5876 5.12791 22.7376 11.3129 22.7376ZM13.0269 12.9827C13.8697 12.0933 13.684 10.9026 12.6841 10.1853L6.81344 6.03948C6.29919 5.66648 5.75642 6.21162 6.1278 6.72805L10.2558 12.6241C10.97 13.6282 12.1556 13.8291 13.0269 12.9827Z" fill="#B8B5FF"/>
                </svg>
                {
                  isExpanded && (<span>Extension Settings</span>)
                }
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className={`expand-block ${isExpanded ? 'justify-content-end' : 'justify-content-center'}`}>
        <span onClick={onExpandBlockClick}>{isExpanded ? '<' : '>'}</span>
      </div>
    </div>
  );
};

export default memo(DashboardSideMenu);
