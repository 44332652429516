import React, {useEffect, useState, memo} from 'react'
import { Formik, Form, Field } from 'formik';
import { useToasts } from 'react-toast-notifications';

import "./extension-settings.scss";
import {EXTENSION_SETTINGS_INIT} from "./extension-settings.constants";
import {Get, Put} from "../../common/services/generic";

const ExtensionSettings = () => {
  const [model, setModel] = useState(null);
  const { addToast } = useToasts();

  useEffect(() => {
    if (!model) {
      Get('home_user_setting').then(res => {
        if (res) {
          setModel({
            autoTestNewNetworks: res.auto_testing_on_new_network,
            autoTestAfterServiceInterruption: res.auto_testing_on_detective_service,
            enableDailyTesting: res.daily_testing,
          })
        } else {
          setModel(EXTENSION_SETTINGS_INIT);
        }
      });
    }
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSubmit = (values) => {
    Put('update_user_setting',{
      auto_testing_on_new_network: values.autoTestNewNetworks,
      auto_testing_on_detective_service: values.autoTestAfterServiceInterruption,
      daily_testing: values.enableDailyTesting,
    }).then(res => {
      if (res) {
        console.log('values', values);
        setModel(values);
        addToast('You have successfully updated the settings.', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
        window.postMessage({
          direction: "from-page-script",
          message: "settings-update",
          data: values,
        });
      }
    });
  };

  return (
    <div className="extension-settings">
      <h5>Extension Settings</h5>
      {
        !model ? (
          <span>Loading settings...</span>
        ) : (
          <Formik
            enableReinitialize
            initialValues={model}
            onSubmit={(values) => {
              handleOnSubmit(values);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="d-flex settings-block flex-column">
                  <span className="title">General</span>
                  <div className="d-flex flex-row" role="group">
                    <div className="d-flex flex-column flex-1">
                      <label className="d-flex">
                        <Field type="checkbox" name="autoTestNewNetworks" />
                        <div className="d-flex flex-column">
                          <span className="field-title">Enable automatic testing on new network connections</span>
                          <span className="field-desc">When a new internet connection is detected, HubbleIQ will automatically run a test, indicated by a grey ‘IQ’ toolbar icon. This ensures that you have updated network quality information.</span>
                        </div>
                      </label>
                      <label className="d-flex mt-30">
                        <Field type="checkbox" name="autoTestAfterServiceInterruption" />
                        <div className="d-flex flex-column">
                          <span className="field-title">Automatically test after a detected service interruption</span>
                          <span className="field-desc">
                        If a service provider outage is detected, HubbleIQ will automatically run a test once service is restored. NOTE: If you are experiencing frequent outages, this may trigger a high volume of tests. Outages will always be logged in the 'results history' log for tracking.
                      </span>
                        </div>
                      </label>
                      <label className="d-flex mt-30">
                        <Field type="checkbox" name="enableDailyTesting" />
                        <div className="d-flex flex-column">
                          <span className="field-title">Enable daily testing.</span>
                          <span className="field-desc">
                        If test results are over 12 hours old, HubbleIQ will automatically run a test and update the IQ status symbol. Tests will only auto run when the machine is not in sleep or standby mode.
                      </span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mt-50">
                  <button className="submit-btn" type="submit" disabled={isSubmitting}>
                    Save Settings
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )
      }
    </div>
  )
};

export default memo(ExtensionSettings);
